import {dom, library} from '@fortawesome/fontawesome-svg-core';

import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faHome} from "@fortawesome/pro-solid-svg-icons/faHome";
import {faChartLine} from "@fortawesome/pro-solid-svg-icons/faChartLine";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {faCogs} from "@fortawesome/pro-solid-svg-icons/faCogs";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import {faFilePdf} from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import {faGlobe} from "@fortawesome/pro-solid-svg-icons/faGlobe";
import {faLock} from "@fortawesome/pro-solid-svg-icons/faLock";
import {faPlug} from "@fortawesome/pro-solid-svg-icons/faPlug";
import {faUsers} from "@fortawesome/pro-solid-svg-icons/faUsers";
import {faUserChart} from "@fortawesome/pro-solid-svg-icons/faUserChart";
import {faUserTie} from "@fortawesome/pro-solid-svg-icons/faUserTie";


library.add(
  faHome,
  faGlobe, faCalendar, faUsers, faEnvelope, faChartLine, faLock, faCheck, faCogs,
  faPlug,
  faFilePdf,
  faUserChart,
  faUserTie,
);

dom.i2svg();
// dom.watch();


