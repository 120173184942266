import {RodoOptionsModel} from "./rodoOptions.model";

export class Rodo {
  protected options: RodoOptionsModel = {};
  protected defaultOptions: RodoOptionsModel = {
    domain: '',
    style: 'dark',
    position: 'bottom',
    opacity: .85,
    // displayTime: 0,
    text: 'Ten serwis wykorzystuje pliki cookies. Korzystanie z witryny lub kliknięcie "Akceptuję wszystkie" oznacza zgodę na przechowywanie plików cookies naszych i naszych partnerów na tym urządzeniu. Pliki te są wykorzystywane w celu poprawy działania strony, analizy użytkowania strony oraz dostosowania reklam do Twoich preferencji. W każdym momencie możesz zmienić ustawienia na stronie Polityki prywatności.',
    privacyPolicyName: 'Polityka cookies',
    privacyPolicyUrl: '/polityka-cookies/',
    divID: 'CookieAlert',
    cookieName: '_rodoRequired',
    cookieValue: '1',
    cookieExpireInMonths: 3 * 12,
    additionalCookies: [
      {
        // title: 'Analiza odwiedzin',
        // description: '<p>Potrzebujemy tych danych by wiedzieć jak użytkownicy poruszają się po naszej stronie, jakie artykuły czytają, co można poprawić i ulepszyć. Mam nadzieję, że wyrazisz zgodę na te pliki - zależy nam na nich</p>',
        // usedBy: ['Google Analytic', 'AddThis'],
        cookieName: '_rodoAnalityczne',
        cookieValue: '1'
      },

      {
        // title: 'Analiza odwiedzin',
        // description: '<p>Potrzebujemy tych danych by wiedzieć jak użytkownicy poruszają się po naszej stronie, jakie artykuły czytają, co można poprawić i ulepszyć. Mam nadzieję, że wyrazisz zgodę na te pliki - zależy nam na nich</p>',
        // usedBy: ['Google Analytic', 'AddThis'],
        cookieName: '_rodoMarketingowe',
        cookieValue: '1'
      }
    ],
    cookieSettingsName: 'Ustawienia cookies'
  };

  constructor(options: RodoOptionsModel = {}) {
    this.options = {...this.defaultOptions, ...options};
  }

  init() {
    window.onload = () => {
      /* Add div with allert to website */
      if (this.checkCookie(this.options.cookieName) != this.options.cookieValue) {
        this.displayDiv();
      }
    }
  }

  protected displayDiv() {
    /* Create div with allert */
    const div = document.createElement('div');
    div.setAttribute('id', this.options.divID);

    /* Add style to created div */
    let style = 'position:fixed;' + this.options.position + ':-1px;left:0px;right:0px;width:100%;z-index:1000;padding:10px;font-size:70%;opacity:' + this.options.opacity + ';';
    let buttonStyle = '';
    let buttonAprooveStyle = '';
    switch (this.options.style) {
      case 'light':
        style += 'background-color:#FFF; color:#373737; border-top:1px solid #ccc; border-bottom:1px solid #ccc;';
        buttonAprooveStyle = 'color:#000; font-weight:bold;';
        buttonStyle = 'color:#565656;';
        break;
      case 'dark':
        style += 'background-color:#1b1b1b; color:#999; border-top:1px solid #444; border-bottom:1px solid #444; ';
        buttonAprooveStyle = 'color:#fff; font-weight:bold;';
        buttonStyle = 'color:#aaa;';
        break;
    }
    div.setAttribute('style', style);

    /* Buttons */
    let buttons = '<div style="width:350px;vertical-align:middle;text-align:center;margin:auto">';
    buttons += '<a href="' + this.options.privacyPolicyUrl + '" style="' + buttonStyle + '" target="_blank">' + this.options.privacyPolicyName + '</a> &bull; ';
    // buttons += '<a href="javascript:void(null)" id="' + this.options.divID + 'Settings" style="' + buttonStyle + '">' + this.options.cookieSettingsName + '</a> &bull; ';
    buttons += '<a href="javascript:void(null)" id="' + this.options.divID + 'AprooveAll" style="' + buttonAprooveStyle + '">Akceptuję wszystkie</a>';
    buttons += '</div>';

    /* Add content to created div */
    const content = '<div style="text-align:center;">' + this.options.text + buttons + '</div>';
    div.innerHTML = content;

    document.body.appendChild(div);

    document.getElementById(this.options.divID + 'AprooveAll').addEventListener('click', () => {
      this.aprooveAll();
      this.removeDiv();
    }, false);

    // document.getElementById(this.options.divID + 'Settings').addEventListener('click', () => {
    //   // console.log('settings');
    //   this.openSettings();
    // }, false);
  }

  protected aprooveAll() {
    this.aproove(this.options.cookieName, this.options.cookieValue);
    if (this.options.additionalCookies) {
      this.options.additionalCookies.forEach((item) => {
        this.aproove(item.cookieName, item.cookieValue);
      })
    }
  }

  protected aproove(name, value) {
    const expire = new Date();
    expire.setMonth(expire.getMonth() + this.options.cookieExpireInMonths);
    this.setCookie(name, value, expire);
  }

  protected removeDiv() {
    var div = document.getElementById(this.options.divID);
    document.body.removeChild(div);
  }

  protected checkCookie(name) {
    if (document.cookie !== "") {
      const toCookie = document.cookie.split("; ");
      for (let i = 0; i < toCookie.length; i++) {
        const CookieName = toCookie[i].split("=")[0];
        const CookieValue = toCookie[i].split("=")[1];
        if (CookieName == name) {
          return decodeURI(CookieValue);
        }
      }
    }
  }


  protected setCookie(name, value, expire) {
    document.cookie = name + "=" + encodeURI(value) + ((expire === null) ? "" : ("; expires=" + expire.toGMTString())) + (this.options.domain ? ";domain=" + this.options.domain : "") + ";path=/";
  }

//   protected openSettings() {
//     const div = document.createElement('div');
//     div.setAttribute('id', this.options.divID + 'SettingsModal');
//
//     /* Add style to created div */
//     let style = 'position:fixed;top:10%; left:50%; width:500px;z-index:2000;padding:2rem;margin-left:-250px;';
//     switch (this.options.style) {
//       case 'light':
//         style += 'background-color:#FFF; color:#373737; border-top:1px solid #ccc; border-bottom:1px solid #ccc;';
//         break;
//       case 'dark':
//         style += 'background-color:#1b1b1b; color:#999; border-top:1px solid #444; border-bottom:1px solid #444; ';
//         break;
//     }
//     div.setAttribute('style', style);
//
//     /* Add content to created div */
//     let content = `<div style="text-align:center;"><h2>Ustawienia Cookies</h2></div>
// <p>Nasza strona wykorzystuje pliki cookies by działała poprawnie oraz by lepiej dostosować jej treść do Twoich preferencji. Dane w takich plikach nie pozwalają dokładnie Cię zidentyfikować - zawierają jedynie informacje o Twoich działaniach. Pliki cookies podzieliliśmy na kategorie.</p>
// <h4>Wymagane pliki cookies</h4>
// <p>Są to pliki cookied, które wymagamy do poprawnego działania strony. Są to np. dane sesyjne. Potrzebne np. do tego by informacją o plikach cookies nie wyświetlała się za każdym razem gdy przyjdziesz na tę stronę. By zabronić nam używania tych plików musisz zmienić ustawienia w swojej przeglądarce.</p>
// `;
//     this.options.additionalCookies.forEach((item) => {
//       content += `<h4>${item.title}</h4>
// ${item.description}
// <div>Używane przez:</div><ul>
// `;
//       item.usedBy.forEach((item2) => {
//         content += `<li>${item2}</li>`;
//       })
//       content += '</ul>';
//     });
//     div.innerHTML = content;
//
//     document.body.appendChild(div);
//   }
}