import "bootstrap";
// import {Collapse} from "bootstrap";
import "../font-awesome/font-awesome-icons";
import * as AOS from "aos";
import 'aos/dist/aos.css';
import {Rodo} from "../rodo/Rodo";


document.querySelectorAll('*[data-aos|="nojs"]').forEach( (el: HTMLElement) => {
  el.setAttribute('data-aos', el.getAttribute('data-aos').replace('nojs-', ''));
});
// console.log(AOS);
AOS.init({
  once:     true,
  easing:   'ease-in-out-back',
  duration: 1000
});
// CookieAlert.init();
const rodo = new Rodo({
  privacyPolicyUrl:  '/cookie/',
  additionalCookies: [
    {
      cookieName:  '_rodoAnalityczne',
      cookieValue: '1'
    },
  ],
});
rodo.init();

/**
 Bonus
 **/
// if ($("#xmascounter").length) {
//     function calculateXmasCounter() {
//         //calculate
//         let now = new Date();
//         let xmas = new Date(2017, 4, 20, 0, 0, 0, 0);
//         //Xmas 2011 (be careful: 11 is December)
//         let hoursleft = 23 - now.getHours();
//         let minutesleft = 59 - now.getMinutes();
//         let secondsleft = 59 - now.getSeconds();
//         let daysleft = Math.floor((xmas - now) / (1000 * 60 * 60 * 24));
//         //format 0 prefixes
//         if (minutesleft < 10)
//             minutesleft = "0" + minutesleft;
//         if (secondsleft < 10)
//             secondsleft = "0" + secondsleft;
//         //display
//         document.getElementById('xmascounter').innerHTML = daysleft + " dni i " + hoursleft + ":" + minutesleft + ":" + secondsleft;
//     }
//
//     calculateXmasCounter();
//     setInterval(calculateXmasCounter, 1000);
// }
